import { PasswordField } from '../../../components/password-field';
import { LoginSubmitForm } from '../../../types';
import { getErrorMessage } from '../../../utils/validation-utils';
import { useLoginContext } from '../../login-container';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Stack,
  Link,
  TextField,
  Typography,
} from '@taxfix/ds-components';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { routes } from '../../../router/routes';
import { useNavigateWithTransition } from '../../../hooks/navigate-with-transition';
import { auth0Config } from '../../../config';

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required().min(4),
  })
  .required();

export const LoginWithEmailPassword = () => {
  const { onEmailPasswordLogin, loading, userEmail } = useLoginContext();
  const navigate = useNavigateWithTransition();
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm<LoginSubmitForm>({
    resolver: yupResolver(schema),
    values: {
      email: userEmail,
      password: '',
    },
  });
  const { t } = useTranslation();

  const submitHandler = () => {
    onEmailPasswordLogin({
      email: getValues('email'),
      password: getValues('password'),
    });
  };

  const handleForgotPassword = () => {
    navigate(`/${routes.resetPassword}`, { state: { userEmail } });
  }

  const handleBacktoOtp = () => {
    navigate(`/${routes.login}/${routes.emailLogin}/${routes.otpScreen}`, { state: { userEmail } });
  }

  const emailError = getErrorMessage(t, errors.email?.type);
  const passwordError = getErrorMessage(t, errors.password?.type);

  return (
    <Box height={'100%'} alignItems={'center'} justifyContent={'center'}>
      <Box
        width={{ xs: 'full', sm: 479 }}
        minHeight={400}
        height={{ xs: '100%', sm: 'auto' }}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
            handleSubmit(submitHandler)();
          }}
          noValidate
          style={{ height: '100%' }}
        >
          <Stack
            justifyContent={{ xs: 'space-between', sm: 'unset' }}
            height="100%"
            space={{ lg: 2 }}
          >
            <Stack space={4} padding={3}>
              <Stack space={1.5}>
                <Box mb={2}>
                  <Typography variant={'h2'} color={'text.title'}>
                    {t('login.emailAndPassword.title')}
                  </Typography>
                </Box>
                {userEmail ? (
                  <></>
                ) : (
                  <Controller
                    control={control}
                    name="email"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        testID="email-input"
                        value={field.value}
                        label={`${t('login.emailAndPassword.emailLabel')}`}
                        onChangeText={field.onChange}
                        feedbackText={emailError}
                        isInvalid={Boolean(emailError)}
                        keyboardType="email-address"
                      />
                    )}
                  />
                )}
                <Controller
                  control={control}
                  name="password"
                  render={({ field }) => (
                    <PasswordField
                      {...field}
                      testID="password-input"
                      value={field.value}
                      label={`${t('login.emailAndPassword.passwordLabel')}`}
                      onChangeText={field.onChange}
                      feedbackText={passwordError}
                      isInvalid={Boolean(passwordError)}
                    />
                  )}
                />
                <Link href={`#`} onPress={handleForgotPassword}>
                  {t('login.emailAndPassword.forgotPassword')}
                </Link>
                <Link href={auth0Config.supportURL} isExternal>
                  {t('login.emailAndPassword.haveTrouble')}
                </Link>
              </Stack>
            </Stack>
            <input type="submit" hidden />
            <Stack space={1} p={3}>
              <Button isDisabled={loading} isLoading={loading} onPress={handleSubmit(submitHandler)} testID="login-with-email-password-button">
                {t('login.emailAndPassword.buttonText')}
              </Button>
              <Button isDisabled={loading} onPress={handleBacktoOtp} variant="tertiary">
                {t('login.emailAndPassword.backToOtp')}
              </Button>
            </Stack>
          </Stack>
        </form>
      </Box>
    </Box>
  );
};

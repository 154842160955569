import { PasswordField } from '../../components/password-field';
import { LoginSubmitForm } from '../../types';
import { getErrorMessage } from '../../utils/validation-utils';
import { useAccountLinkingHandlers } from '../account-linking-password-container';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Stack, Typography, Link } from '@taxfix/ds-components';
import User from '@taxfix/ds-icons/User';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { auth0Config } from '../../config';
import { routes } from '../../router/routes';
import { useNavigateWithTransition } from '../../hooks/navigate-with-transition';
import { useLocation } from 'react-router-dom';

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required().min(4),
  })
  .required();

export const AccountLinkingPasswordStep = () => {
  const { state } = useLocation();
  const navigate = useNavigateWithTransition();
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
    setValue,
  } = useForm<LoginSubmitForm>({
    resolver: yupResolver(schema),
  });
  const { t } = useTranslation();
  const { accountLinkingEmail, onSubmit, loading, onSecondaryBtnClick } =
    useAccountLinkingHandlers();
  const userEmail = accountLinkingEmail || state?.email || '';

  useEffect(() => {
    if (userEmail) {
      setValue('email', userEmail);
    }
  }, [userEmail, setValue]);

  const handleForgotPassword = () => {
    navigate(`/${routes.resetPassword}`, { state: { userEmail } });
  }

  const handleBacktoOtp = () => {
    navigate(`/${routes.login}/${routes.emailLogin}/${routes.otpScreen}`, { state: { userEmail } });
  }

  const submitHandler = () => {
    onSubmit({
      email: getValues('email'),
      password: getValues('password'),
    });
  };

  const passwordError = getErrorMessage(t, errors.password?.type);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit(submitHandler)();
      }}
      noValidate
      style={{ height: '100%' }}
    >
      <Stack
        justifyContent={{ xs: 'space-between', sm: 'unset' }}
        height="100%"
        space={{ lg: 2 }}
      >
        <Box padding={3}>
          <Stack space={1}>
            <Box>
              <Typography variant={'h2'} color={'text.title'}>
                {t('accountLinkingPassword.title')}
              </Typography>
            </Box>
            <Stack space={1} direction="row" mt={2} mb={2}>
              <User color="text.secondary" />
              <Typography variant={'body'}>{userEmail}</Typography>
            </Stack>
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <PasswordField
                  {...field}
                  value={field.value}
                  label={`${t('accountLinkingPassword.passwordLabel')}`}
                  onChangeText={field.onChange}
                  feedbackText={passwordError}
                  isInvalid={Boolean(passwordError)}
                />
              )}
            />
            <Link href={`#`} onPress={handleForgotPassword}>
              {t('accountLinkingPassword.forgotPassword')}
            </Link>
            <Link href={auth0Config.supportURL} isExternal>
              {t('accountLinkingPassword.haveTrouble')}
            </Link>
          </Stack>
        </Box>
        <input type="submit" hidden />
        <Stack padding={3}>
          <Button isDisabled={loading} isLoading={loading} onPress={handleSubmit(submitHandler)}>
            {t('accountLinkingPassword.confirmBtnText')}
          </Button>
          <Button isDisabled={loading} onPress={handleBacktoOtp} variant="tertiary">
            {t('accountLinkingPassword.backToOtp')}
          </Button>
          <Button isDisabled={loading} onPress={onSecondaryBtnClick} variant="tertiary">
            {t('accountLinkingPassword.cancelBtnText')}
          </Button>
        </Stack>
      </Stack>
    </form >
  );
};
